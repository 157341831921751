<template>
  <div
    v-if="recipe.isEmpty"
    data-test="empty-recipe-card"
    class="card recipe-card empty-recipe-card"
    :class="`${recipe.type}-bg`">
    <h6>{{recipe.type}}</h6>
  </div>
  <div
    v-else
    class="card recipe-card"
    data-test="recipe-card"
    :class="{ 'merchandise': recipe.merchandise_id }">
    <img
      :src="getTransformationUrl(recipe.photo, {
        height: 456,
        width: 'auto',
        crop: 'fill',
      })
      "
      class="recipe-img"
      :alt="recipe.cardName1"/>
    <div class="recipe-content">
      <div class="recipe-name-section">
        <div class="recipe-type" data-test="label-type">
          <p>{{recipe.type}}</p>
        </div>
        <h6 data-test="label-card-name-1">
          <a
            :href="`/recipes/${recipe.id}`"
            target="_blank"
            rel="noreferrer noopener">
            {{recipe.cardName1}}
          </a>
        </h6>
        <p data-test="label-card-name-2">{{recipe.cardName2}}</p>
      </div>

      <div class="recipe-info-section">
        <div class="d-flex align-items-center">
          <img
            v-svg-inline
            data-test="icon-time"
            class="icon"
            src="@/assets/svg/time.svg"
            alt="Cooking time"/>
          <span>
            {{(recipe.cookingTime || 0) + (recipe.prep_time || 0)}}
          </span>

          <!-- vertical divider -->
          <div class="divider-vertical"/>

          <span data-test="label-price">
            AED {{getRecipePrice}}
          </span>
        </div>

        <template v-if="week.merchandise">
          <button
            v-if="!recipe.merchandise_id"
            class="btn btn-recipe-action btn-merchandise"
            type="button"
            :disabled="loadingMerchandise || !can(uiPermissions.MARKET_SCHEDULE_MERCHANDISE_CREATE)"
            data-test="btn-add-merchandise"
            @click.prevent="addRecipeToMerchandise">
            <i v-if="loadingMerchandise" class="fe fe-loader spin-icon"/>
            <i v-else class="fe fe-plus"/>
            Merchandise
          </button>
          <button
            v-else
            class="btn btn-recipe-action btn-remove-merchandise"
            type="button"
            :disabled="loadingMerchandise || !can(uiPermissions.MARKET_SCHEDULE_MERCHANDISE_CREATE)"
            data-test="btn-remove-merchandise"
            @click.prevent="removeRecipeFromMerchandise">
            <i v-if="loadingMerchandise" class="fe fe-loader spin-icon"/>
            <i v-else class="fe fe-minus"/>
            Merchandise
          </button>
        </template>

        <button
          v-if="can(uiPermissions.MENU_SCHEDULE_UPDATE) && week.status !== MENU_STATUS.PUBLISHED"
          class="btn btn-outline-danger btn-recipe-action btn-remove-recipe"
          :disabled="removingRecipe"
          data-test="btn-remove-recipe"
          @click.prevent="handleRecipeRemove">
          <i v-if="removingRecipe" class="fe fe-loader spin-icon"/>
          <i v-else class="fe fe-trash delete-icon"/>
          Remove
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {market} from '@/services';

const MENU_STATUS = {
  PUBLISHED: 'published',
  CONCEPT: 'concept',
};

export default {
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    week: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      MENU_STATUS,
      removingRecipe: false,
      loadingMerchandise: false,
    };
  },
  computed: {
    getRecipePrice() {
      return this.numeral(parseFloat(this.recipe?.unit_price) + parseFloat(this.recipe?.vat)).format('0,0[.]00');
    },
  },
  methods: {
    async addRecipeToMerchandise() {
      try {
        this.loadingMerchandise = true;
        const merchandise = this.week.merchandise;

        await market.addRecipeToMerchandise(merchandise.id, {
          recipe_id: this.recipe.id,
        });

        this.recipe.merchandise_id = merchandise.id;
        this.$emit('onSuccess', 'Recipe added to merchandise successfully.');
      }
      catch (error) {
        this.$emit('onError', error, 'Failed to add recipe to merchandise.');
      }
      finally {
        this.loadingMerchandise = false;
      }
    },
    async removeRecipeFromMerchandise() {
      try {
        this.loadingMerchandise = true;
        const merchandise = this.week.merchandise;

        await market.removeRecipeFromMerchandise(merchandise.id, this.recipe.id);

        this.recipe.merchandise_id = null;
        this.$emit('onSuccess', 'Recipe removed from merchandise successfully.');
      }
      catch (error) {
        this.$emit('onError', error, 'Failed to remove recipe from merchandise.');
      }
      finally {
        this.loadingMerchandise = false;
      }
    },
    async handleRecipeRemove() {
      try {
        this.removingRecipe = true;

        const weeklyMenuId = this.week.weekly_menu_id;
        const recipeId = this.recipe.id;
        await market.removeItemFromSchedule({weeklyMenuId, recipeId});
        this.$emit('onSuccess', 'Market item removed successfully.', false, true);
      }
      catch (error) {
        this.$emit('onError', error, 'Failed to remove market item from schedule.');
      }
      finally {
        this.removingRecipe = false;
      }
    },
  },
};
</script>

<style scoped>
.recipe-card {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;

  &.merchandise {
    border-color: #BC8800;
    box-shadow: 0px 4px 4px rgba(188, 136, 0, 0.25);
  }
}

.empty-recipe-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #F7F7F7;
  min-width: 180px;

  h6 {
    margin: 0;
    text-transform: capitalize;
  }
}

.recipe-img {
  width: 100%;
  height: 140px;
  object-fit: cover;
}

.recipe-content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  flex: 1;

  h6 {
    color: #454142;
    font-size: 14px;
    font-style: normal;
    font-weight: 590;
    line-height: 140%;
    letter-spacing: -0.42px;
    margin: 0;
  }

  p {
    color: #6C757D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.42px;
    margin: 0;
  }
}

.recipe-type {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 2px;
  margin-bottom: 6px;

  border-radius: 4px;
  background-color: #EDF0F1;
  width: fit-content;

  p {
    color: var(--text-primary, #454142);
    font-size: 0.8rem;
    font-style: normal;
    line-height: 150%;
    letter-spacing: -0.384px;

    text-transform: capitalize;
  }
}

.icon {
  width: 16px;
  height: 16px;
}

.recipe-info-section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  span {
    color: #484848;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    line-height: 130%;
    letter-spacing: -0.42px;
  }

  .btn-recipe-action {
    height: 36px;
    padding: 6px 20px;
    border-radius: 6px;
    border: 1px solid #E0E5ED;

    &.btn-merchandise:hover:not(:disabled) {
      background-color: #B0B0B0;
      color: #FFF;
    }

    &.btn-remove-merchandise {
      background-color: #FEF4D7;

      &:hover:not(:disabled) {
        background-color: #EAD283;
      }
    }

    &.btn-remove-recipe {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      color: #EC6E58;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -0.42px;

      &:hover:not(:disabled) {
        color: #FFF;
      }
    }
  }
}

.divider-vertical {
  width: 1px;
  height: 70%;
  background-color: #484848;
  margin: 0 8px;
}

.dessert-bg {
  background-color: #fdf7f0;

  h6 {
    color: #8d5416;
  }
}

.breakfast-bg {
  background-color: #f6fcef;

  h6 {
    color: #477718;
  }
}

.side-bg {
  background-color: #fefcf0;

  h6 {
    color: #b09107;
  }
}
</style>
