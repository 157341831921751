<template>

  <div>
    <date-picker
      v-if="disableDays || disableSaturday || disableThursday"
      v-model="data"
      :disabledDate="disabledDays"
      :confirm="value.confirm"
      input-class="form-control"
      :lang="lang"
      :format="value.format ? value.format : 'dddd, D MMM YYYY'"
      :shortcuts="[]"
      :multiple="multiple"
      :clearable="clearable"
      :inline="inline"
      :range="range"
      width="100%"
      placeholder="Please select date(s)"
    />
    <date-picker
      v-else
      v-model="data"
      :confirm="value.confirm"
      input-class="form-control pr-6"
      :lang="lang"
      :format="value.format ? value.format : 'dddd, D MMM YYYY'"
      :shortcuts="[]"
      width="100%"
      :type="value.type || 'date'"
      :multiple="multiple"
      :clearable="clearable"
      :inline="inline"
      :use12h="true"
      :range="range"
      placeholder="Please select date(s)"
    />
  </div>

</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';

export default {
  components: {
    DatePicker,
  },
  props: [
    'disableDays',
    'disableSaturday',
    'disableThursday',
    'value',
    'multiple',
    'clearable',
    'inline',
    'range',
  ],
  data() {
    return {
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        placeholder: {
          date: 'Please select date(s)',
        },
      },
    };
  },
  computed: {
    data: {
      get() {
        if (this.multiple || this.range) {
          return this.value.startDate.map(date => date.toDate());
        }

        return this.value.startDate.toDate();
      },
      set(newValue) {
        if (this.multiple || this.range) {
          // if any of the dates is null, set the whole array to null
          if (this.range && newValue.some(date => date === null)) {
            this.$emit('input', Object.assign({}, this.value, {
              startDate: [],
            }));
            return;
          }

          const formattedDates = newValue.map(date => moment(date));
          this.$emit('input', Object.assign({}, this.value, {
            startDate: formattedDates,
          }));
          return;
        }

        this.$emit('input', Object.assign({}, this.value, {
          startDate: moment(newValue),
        }));
      },
    },
  },
  methods: {
    disabledDays(value) {
      const date = new Date(value);
      const dateDay = date.getDay();

      if (this.disableSaturday) { return dateDay !== 6; }

      if (this.disableThursday) { return dateDay !== 4; }

      // available days should be sunday or end of months
      return dateDay !== 0 && moment(value).clone().endOf('month').date() !== moment(value).clone().date();
    },
  },
};

</script>

<style scoped>
.mx-datepicker {
    width: 100%;
}
</style>
