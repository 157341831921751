<template>
  <div class="container">
    <alert
      v-if="error"
      type="danger"
      @close="error = false">
      {{errorMessage || `Something went wrong; couldn\'t save your changes.`}}
    </alert>
    <alert
      v-if="success"
      type="success"
      @close="success = false">
      {{successMessage || 'Your changes have been saved successfully.'}}
    </alert>

    <div class="page-header">
      <button
        v-if="can(uiPermissions.MENU_SCHEDULE_UPDATE)"
        data-test="btn-new-market-item"
        class="btn btn-primary d-flex align-items-center"
        @click="handleCreateClick">
        <i class="fe fe-plus mr-2" style="font-size: 1rem"/> Schedule market item
      </button>
    </div>

    <div class="row">
      <div class="col">
        <dimmer :active="isLoading">
          <div v-for="(week) in items" :key="week.id">
            <accordion
              :hide-footer="true"
              :option="tabs[week.id]"
              custom-class="week-accordion">
              <template v-slot:header="slotProps">
                <div class="d-flex gap-4 align-items-center" @click.stop="slotProps.collapse">
                  <div :class="`week-status-badge-${week.status}`">
                    <p class="m-0 week-status-badge-text">{{week.status}}</p>
                  </div>
                  <h4 class="m-0 ml-2">{{weekName(week.start_date)}}</h4>

                  <div v-if="week.merchandise" class="merchandise-badge"/>
                </div>
              </template>

              <market-merchandise-form
                v-if="can(uiPermissions.MARKET_SCHEDULE_MERCHANDISE_CREATE)"
                :week="week"
                @onSuccess="handleOnSuccess"
                @onError="handleError"/>

              <div class="d-flex gap-12 flex-wrap p-4">
                <div
                  v-for="recipe in getSortedRecipes(week)"
                  :key="recipe.id"
                  class="max-width-180">
                  <market-recipe-card
                    :week="week"
                    :recipe="recipe"
                    @onSuccess="handleOnSuccess"
                    @onError="handleError"/>
                </div>
              </div>
            </accordion>
          </div>
        </dimmer>
      </div>
    </div>

    <choose-market-item-modal
      :show="showChooseItemModal"
      @show="value => showChooseItemModal = value"
      @close="handleCloseChooseItemModal"
      @submit="handleSelectMarketItem"/>

    <choose-weeks-modal
      :show="showChooseWeeksModal"
      @show="value => showChooseWeeksModal = value"
      @close="handleCloseChooseWeeksModal"
      @submit="handleSelectWeeks"/>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue';
import ChooseMarketItemModal from '@/components/view/schedule-market/choose-market-item-modal/index.vue';
import ChooseWeeksModal from '@/components/view/schedule-market/choose-weeks-modal/index.vue';
import {market} from '@/services';
import {captureException} from '@sentry/vue';
import {orderBy} from 'lodash';
import MarketMerchandiseForm from './components/MarketMerchandiseForm.vue';
import MarketRecipeCard from './components/MarketRecipeCard.vue';

const SCHEDULE_LIMIT = 20;
const NUMBER_OF_PUBLISHED_WEEKS = 4;
const NUMBER_OF_CONCEPT_WEEKS = 4;
const MENU_STATUS = {
  PUBLISHED: 'published',
  CONCEPT: 'concept',
};
const MINIMUM_MARKET_ITEMS = {
  BREAKFAST: 4,
  DESSERT: 2,
  SIDE: 5,
};

export default {
  components: {
    Accordion,
    ChooseMarketItemModal,
    ChooseWeeksModal,
    MarketRecipeCard,
    MarketMerchandiseForm,
  },
  data() {
    return {
      showChooseItemModal: false,
      showChooseWeeksModal: false,
      selectedMarketItem: null,
      error: false,
      errorMessage: null,
      success: false,
      successMessage: null,
      items: [],
      isLoading: false,
      tabs: {},
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handleCreateClick() {
      this.showChooseItemModal = true;
    },
    handleCloseChooseItemModal() {
      this.showChooseItemModal = false;
      this.selectedMarketItem = null;
    },
    handleSelectMarketItem(item) {
      this.selectedMarketItem = item;
      this.showChooseWeeksModal = true;
    },
    handleCloseChooseWeeksModal() {
      this.showChooseWeeksModal = false;
    },
    async handleSelectWeeks(weeks) {
      this.error = false;
      this.success = false;

      this.showChooseWeeksModal = false;
      this.showChooseItemModal = false;

      const selectedWeeks = weeks.map(week => week.name);

      if (!selectedWeeks.length || !this.selectedMarketItem) {
        this.error = true;
        this.errorMessage = 'Please select a market item and at least one week.';
        window.scrollTo(0, 0);
        return;
      }

      try {
        await market.postSchedule({
          recipe_id: this.selectedMarketItem.id,
          weekly_menu_start_dates: selectedWeeks,
        });

        this.success = true;
        this.fetchData();
      }
      catch (error) {
        this.handleError(error, 'Failed to add market items to schedule.');
      }
      finally {
        window.scrollTo(0, 0);
      }
    },
    async fetchData() {
      try {
        this.isLoading = true;
        this.error = false;

        const params = {limit: SCHEDULE_LIMIT, status: [MENU_STATUS.PUBLISHED, MENU_STATUS.CONCEPT]};
        this.items = await market.getSchedule({params});

        const publishedWeeks = this.getWeeksBasedOnStatus(this.items, MENU_STATUS.PUBLISHED, NUMBER_OF_PUBLISHED_WEEKS, 'desc');
        const conceptWeeks = this.getWeeksBasedOnStatus(this.items, MENU_STATUS.CONCEPT, NUMBER_OF_CONCEPT_WEEKS, 'asc');

        this.items = [...conceptWeeks, ...publishedWeeks];

        // create tabs based on the weeks ids for more programmatic control
        this.tabs = this.items.reduce((acc, week) => {
          acc[week.id] = {collapse: true, disabled: false, display: true};
          return acc;
        }, {});
      }
      catch (error) {
        this.handleError(error, 'Failed to fetch schedule for market items.');
      }
      finally {
        this.isLoading = false;
      }
    },
    /**
     * Retrieves a subset of weeks based on their status, sorts them by start date and eventually sorts them by descending order.
     *
     * @param {Array} weeks - The array of weeks to filter and sort.
     * @param {string} status - The status to filter the weeks by.
     * @param {number} numberOfWeeks - The number of weeks to include in the subset.
     * @param {string} sortOrder - The sort order for the weeks.
     * @returns {Array} - The subset of weeks sorted by start date in descending order.
     */
    getWeeksBasedOnStatus(weeks, status, numberOfWeeks, sortOrder) {
      const filteredWeeks = weeks.filter(item => item.status === status);
      const sortedWeeks = orderBy(filteredWeeks, 'start_date', sortOrder);
      const subsetWeeks = sortedWeeks.slice(0, numberOfWeeks);
      return orderBy(subsetWeeks, 'start_date', 'desc');
    },
    addEmptyRecipes(recipes, minimumItems, type) {
      if (recipes.length < minimumItems) {
        for (let i = recipes.length; i < minimumItems; i++) {
          recipes.push({
            id: Math.random(),
            type,
            isEmpty: true,
          });
        }
      }
    },
    getSortedRecipes(week) {
      if (!week?.recipes) {
        return [];
      }

      const dessertRecipes = week.recipes.filter(recipe => recipe.type === 'dessert');
      const breakfastRecipes = week.recipes.filter(recipe => recipe.type === 'breakfast');
      const sideRecipes = week.recipes.filter(recipe => recipe.type === 'side');

      this.addEmptyRecipes(dessertRecipes, MINIMUM_MARKET_ITEMS.DESSERT, 'dessert');
      this.addEmptyRecipes(breakfastRecipes, MINIMUM_MARKET_ITEMS.BREAKFAST, 'breakfast');
      this.addEmptyRecipes(sideRecipes, MINIMUM_MARKET_ITEMS.SIDE, 'side');

      return [...dessertRecipes, ...breakfastRecipes, ...sideRecipes];
    },
    handleError(error, message) {
      if (!error) {
        return;
      }

      console.error(error);

      this.error = true;
      if (error.response?.status === 422 && error.response?.data.message) {
        this.errorMessage = error.response.data.message;
      }
      else {
        captureException(error);
        this.errorMessage = message;
      }
      this.$toasted.error(this.errorMessage);
    },
    handleOnSuccess(message, showBanner = false, fetchData = false) {
      this.error = false;
      this.$toasted.success(message);
      this.success = showBanner;

      if (fetchData) {
        this.fetchData();
      }
    },
  },
};
</script>

<style scoped>
.max-width-180 {
  max-width: 180px;
}

.week-status-badge-concept {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #647ad0;
  margin-left: 2px;
  width: 75px;
}

.week-status-badge-published {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #098761;
  margin-left: 2px;
  width: 75px;
}

.week-status-badge-text {
  color: #ffffff;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.42px;
  margin: 0;
  font-weight: 500;
}

.merchandise-badge {
  padding: 6px;
  border-radius: 100%;
  background-color: #BC8800;
}
</style>

<style>
.week-accordion {
  h5 {
    width: 100% !important;
  }
}
</style>
