<template>
  <div class="listbox">
    <div class="list-options">
      <template v-for="(item, i) in selected">
        <span
          :key="i"
          class="option"
          :class="[
            'selected',
            {
              'text-white bg-primary': primary,
              'text-white bg-secondary': secondary,
            },
          ]"
        >
          {{item.name}}
          <button
            v-if="!disabled && !readonly"
            type="button"
            :title="`Deselect ${item.name}`"
            :aria-label="`Deselect ${item.name}`"
            @click="removeItem(item)"
          >
            <span class="fe fe-x toggle"></span>
          </button>
        </span>
      </template>
      <span v-if="selected.length === 0" class="option not-selected">
        <slot name="no-data-placeholder">No data available</slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HChipList',
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    primary: {
      default: false,
      type: Boolean,
    },
    secondary: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      model: '',
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
    },
  },
  methods: {
    removeItem(index) {
      this.$emit('remove', index);
    },
  },
};
</script>

<style scoped>
.listbox {
  display: flex;
  min-height: 2.375rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.list-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  gap: 8px;
}

.list-options .selected {
  border: 0;
  font-size: 14px;
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  white-space: normal;
}

.list-options .option {
  border: 0;
  font-size: 14px;
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  white-space: normal;
}

.list-options .option.selected {
  background: #e3f2fd;
  color: #039be5;
}

.list-options .option.primary {
  background: #e3e8fd;
  color: #1603e5;
}

.list-options .option.not-selected {
  background: #fde3e3;
  color: #e50303;
}

.list-options .option:first-child {
  margin-left: 0;
}

.list-options .option button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  margin-left: 2px;
  padding: 0;
  text-shadow: 0 1px 0 #fff;
  color: #039be5;
}
</style>
