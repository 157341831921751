<template>
  <div class="choose-market-item-modal">
    <b-modal
      v-model="safeShow"
      centered
      data-test="modal-choose-market-item"
      no-close-on-backdrop
      title="Add new market item"
      size="lg"
      hide-footer
      @close="$emit('close')">

      <template slot="modal-header-close">&nbsp;</template>

      <dimmer :active="listLoading">
        <div class="row d-flex align-items-center gap-8">
          <label class="form-label ml-4"> Filter: </label>
          <div>
            <label
              v-for="(item, key) in filters"
              :key="key"
              class="form-check form-check-inline"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :checked="item.checked"
                @change="handleFilterClick(item)"
              />
              <span class="form-check-label">{{item.name}}</span>
            </label>
          </div>
        </div>

        <div class="recipe-section">
          <div
            v-for="recipe in items"
            :key="recipe.id"
            class="recipe-list-item"
            @click="() => $emit('submit', recipe)">
            <i class="fe fe-plus plus-icon"/>
            <img
              class="recipe-img"
              :src="getTransformationUrl(recipe.photo, {width: 42, height: 42, crop: 'fill'})"
              :alt="recipe.name"/>
            <p class="recipe-name">{{recipe.name}}</p>
          </div>
        </div>
      </dimmer>
    </b-modal>
  </div>
</template>

<script>
import list from '@/mixins/list';
import {recipes} from '@/services';

const MARKET_ITEM_LISTING_LIMIT = 100;
const FILTERS = ['dessert', 'breakfast', 'side'];
const DESSERT_TAGS = [153, 154];
const BREAKFAST_TAGS = [155, 156];
const SIDE_TAGS = [157, 158];

export default {
  name: 'ChooseMarketItemModal',
  mixins: [list],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      filters: [],
      tagsFilter: [...DESSERT_TAGS, ...BREAKFAST_TAGS, ...SIDE_TAGS],
    };
  },
  computed: {
    safeShow: {
      get: function() { return this.show; },
      set: function(newValue) { this.$emit('show', newValue); },
    },
  },
  created() {
    this.filters = FILTERS.map(name => ({name, checked: true}));
  },
  methods: {
    async fetchData(page) {
      if (this.tagsFilter.length === 0) {
        this.items = [];
        return;
      }

      const params = {
        limit: MARKET_ITEM_LISTING_LIMIT,
        page,
        with: 'photo',
        'filters[archived]': 0,
        'filters[status]': ['ready-to-be-drafted'],
        'filters[tags]': this.tagsFilter,
        'filters[category]': ['market'],
      };

      return recipes.search(params);
    },
    fetchRecipes() {
      this.refresh();
    },
    handleFilterClick(filter) {
      this.filters = this.filters.map(f => {
        if (f.name === filter.name) {
          return {...f, checked: !f.checked};
        }
        return f;
      });

      this.tagsFilter = this.filters
        .filter(f => f.checked)
        .map(f => {
          if (f.name === 'dessert') {
            return DESSERT_TAGS;
          }
          if (f.name === 'breakfast') {
            return BREAKFAST_TAGS;
          }
          if (f.name === 'side') {
            return SIDE_TAGS;
          }
          return [];
        })
        .flat();

      this.fetchRecipes();
    },
  },
};
</script>

<style scoped>
.choose-market-item-modal {
  display: block;
}

.recipe-section {
  overflow-y: auto;
  max-height: 60vh;
}

.recipe-list-item {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #E0E5EC;
  cursor: pointer;
}

.recipe-list-item:hover {
  background-color: #F5F8FA;
}

.recipe-list-item:last-child {
  border-bottom: none;
}

.plus-icon {
  font-size: 20px;
  color: #5DBB00;
  margin-left: 10px;
}

.recipe-img {
  width: 42px;
  height: 42px;
  margin-left: 20px;
  object-fit: cover;
  border-radius: 50%;
}

.recipe-name {
  margin: 0;
  margin-left: 8px;
  color: #467FCF;
  font-size: 15px;
}
</style>
