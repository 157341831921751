<template>
  <div class="choose-weeks-modal">
    <b-modal
      v-model="safeShow"
      centered
      data-test="modal-choose-weeks"
      no-close-on-backdrop
      title="Choose weeks to schedule"
      size="md"
      @close="handleClose"
      @cancel="handleClose"
      @ok="handleSubmit">

      <template slot="modal-header-close">&nbsp;</template>

      <div class="form-group">
        <calendar-day-picker
          v-model="weeklyMenu"
          class="calendar-container"
          :disableThursday="true"
          :multiple="true"
          :clearable="false"
          :inline="true"
          @input="addWeeksToList"></calendar-day-picker>
      </div>

      <h-chip-list
        :value="selectedWeeks"
        :disabled="true">
        <template #no-data-placeholder>
          No weeks selected
        </template>
      </h-chip-list>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import CalendarDayPicker from '@/components/CalendarDayPicker';
import HChipList from '@/components/ui/HChipList';

export default {
  name: 'ChooseWeeksModal',
  components: {
    CalendarDayPicker,
    HChipList,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      weeklyMenu: {
        startDate: [],
      },
      selectedWeeks: [],
    };
  },
  computed: {
    safeShow: {
      get: function() { return this.show; },
      set: function(newValue) { this.$emit('show', newValue); },
    },
  },
  created() {
  },
  methods: {
    addWeeksToList(weekObject) {
      this.selectedWeeks = [];

      const formattedWeeks = weekObject.startDate.map(week => ({name: moment(week).format('YYYY-MM-DD')}));
      this.selectedWeeks.push(...formattedWeeks);
    },
    resetComponentState() {
      this.selectedWeeks = [];
      this.weeklyMenu = {
        startDate: [],
      };
    },
    handleClose() {
      this.$emit('close');
      this.resetComponentState();
    },
    handleSubmit() {
      this.$emit('submit', this.selectedWeeks);
      this.resetComponentState();
    },
  },
};
</script>

<style scoped>
.choose-weeks-modal {
  display: block;
}

.calendar-container {
  margin: auto;
  width: fit-content;
}
</style>
