<template>
  <form class="d-flex px-4 pt-4 align-items-start gap-12" @submit.prevent="saveMerchandise()">
    <div class="pb-4 position-relative">
      <input
        v-model="merchandise.name"
        type="text"
        class="form-control"
        placeholder="Merchandise name"
        maxlength="16"
        data-test="txt-merchandise-name"
        :readonly="merchandise.id"
        required/>
      <small class="position-absolute text-muted right-0" data-test="label-merchandise-name-hint">
        {{merchandise.name.length}}/16
      </small>
    </div>
    <button
      v-if="!merchandise.id"
      class="btn btn-primary"
      type="submit"
      data-test="btn-save-merchandise"
      :disabled="loading || !merchandise.name">
      <i v-if="loading" class="fe fe-loader spin-icon"/>
      Save
    </button>
    <template v-else>
      <button
        v-b-tooltip
        type="button"
        title="Edit merchandise"
        class="btn btn-outline-primary"
        data-test="btn-edit-merchandise"
        @click.prevent="merchandise.id = null">
        <i class="fe fe-edit"/>
      </button>
      <button
        v-b-tooltip
        type="button"
        title="Remove merchandise"
        class="btn btn-danger"
        data-test="btn-remove-merchandise"
        :disabled="loading"
        @click.prevent="removeMerchandise()">
        <i v-if="loading" class="fe fe-loader spin-icon"/>
        <i v-else class="fe fe-trash delete-icon"></i>
      </button>
    </template>
  </form>
</template>
<script>
import {market} from '@/services';

export default {
  props: {
    week: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      merchandise: {
        id: null,
        name: '',
      },
    };
  },
  mounted() {
    if (this.week.merchandise) {
      this.merchandise = this.week.merchandise;
    }
  },
  methods: {
    async saveMerchandise() {
      this.loading = true;

      try {
        const {id, name} = await market.saveMerchandise({
          name: this.merchandise.name,
          weekly_menu_id: this.week.weekly_menu_id,
        });

        this.merchandise.id = id;
        this.merchandise.name = name;
        this.week.merchandise = this.merchandise;

        this.$emit('onSuccess', 'Merchandise saved successfully.');
      }
      catch (error) {
        this.$emit('onError', error, 'Failed to save merchandise.');
      }
      finally {
        this.loading = false;
      }
    },
    async removeMerchandise() {
      if (!confirm('Are you sure you want to remove this merchandise?')) {
        return;
      }

      try {
        this.loading = true;
        await market.removeMerchandise(this.merchandise.id);

        this.merchandise.id = null;
        this.merchandise.name = '';
        this.week.merchandise = null;

        this.week.recipes.forEach(recipe => {
          recipe.merchandise_id = null;
        });

        this.$emit('onSuccess', 'Merchandise removed successfully.');
      }
      catch (error) {
        this.$emit('onError', error, 'Failed to remove merchandise.');
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>
