<template>
  <div
    v-if="option.display"
    class="card"
    :class="customClass">
    <div
      class="card-header"
      :class="{'disabled': option.disabled, 'cursor-pointer': !option.disabled, 'active': !collapse}"
      @click.self="toggleCollapse">
      <h5 class="mb-0">
        <slot name="header" :collapse="toggleCollapse">{{header}}</slot>
      </h5>
      <div class="card-options align-items-center">
        <slot name="card-options" :collapse="collapse"></slot>
        <i
          v-if="!option.disabled"
          class="fe"
          :class="collapse ? 'fe-chevron-down' : 'fe-chevron-up'"
          @click.self="toggleCollapse"></i>
      </div>
    </div>
    <div class="collapse" :class="{'show': !collapse}">
      <slot></slot>
      <div v-if="!hideFooter" class="card-footer d-flex justify-content-between">
        <slot name="footer">
          <button class="btn btn-default" @click="$emit('cancel')">Cancel</button>
          <button class="btn btn-primary" @click="$emit('apply')">Apply</button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customClass: {
      default: '',
      type: String,
    },
    header: {
      type: String,
    },
    hideFooter: {
      default: false,
      type: Boolean,
    },
    option: {
      default: () => {
        return {
          collapse: false,
          disabled: false,
          display: true,
        };
      },
      type: Object,
    },
  },
  data() {
    return {
      collapse: this.option.collapse,
    };
  },
  watch: {
    'option.collapse': function(newVal) {
      this.collapse = newVal;
    },
  },
  methods: {
    toggleCollapse() {
      return !this.option.disabled ? this.collapse = !this.collapse : null;
    },
  },
};
</script>

<style scoped>
    .card .disabled:not(.active) {
        background-color: #ddd;
        cursor: not-allowed;
    }
</style>
